:root:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Smile', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  .adm-form-item-label .adm-form-item-required-text {
    color: var(--adm-color-danger) !important;
  }
}


